import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../shared/useAuth";

const Home = () => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();

  if (loading) {
    return null;
  }

  if (user) {
    navigate("/dashboard");
  } else {
    window.location.href = "https://www.goldenrecord.app";
  }

  return;
};

export default Home;
